import { SVGProps } from 'react';

const VideoCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M11.1 3H5C2.2 3 0 5.2 0 8v8c0 2.8 2.2 5 5 5h9c2.6 0 4.7-1.9 5-4.5l1.8 1.4c.9.7 2.1.5 2.8-.4.3-.3.4-.8.4-1.2V7.7c0-.7-.4-1.4-1.1-1.7-.7-.3-1.5-.3-2.1.2L19 7.5C18.7 4.9 16.6 3 14 3h-2.9zM17 16c0 1.7-1.3 3-3 3H5c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h9c1.7 0 3 1.3 3 3v8zm5 .3L19 14v-4l3-2.2v8.5z" />
  </svg>
);

export default VideoCamera;
