import { SVGProps } from "react"

const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M23 16a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1Z" />
    <path d="m5.293 13.538 4.586 4.587a3.007 3.007 0 0 0 4.242 0l4.586-4.586a1 1 0 1 0-1.414-1.414L13 16.416V1a1 1 0 0 0-2 0v15.417l-4.293-4.293a1 1 0 1 0-1.414 1.414Z" />
  </svg>
)

export default Download
