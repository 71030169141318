import { ReactNode, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import useKeyPress from '../hooks/useKeypress';
import AngleLeft from '../assets/icons/angle-left';
import SlideshowProgress from './slideshow-progress';
import ScrollableContainer from './scrollable-container';

type Props = {
  slides: ReactNode[];
};

const SlideShow = (props: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const isLeftKeyPressed = useKeyPress('ArrowLeft');
  const isRightKeyPressed = useKeyPress('ArrowRight');

  const nextSlide = () => {
    if (currentSlide < props.slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const previousSlide = () => {
    if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
  };

  useEffect(() => {
    if (isLeftKeyPressed) previousSlide();
    if (isRightKeyPressed) nextSlide();
  }, [isLeftKeyPressed, isRightKeyPressed]);

  return (
    <div className="relative flex items-center justify-center w-full h-full">
      {props.slides.length > 1 && (
        <Arrow
          direction="left"
          handleClick={previousSlide}
          disabled={currentSlide === 0}
        />
      )}
      <div className="relative flex flex-col w-11/12 h-full overflow-hidden">
        <SlideshowProgress
          currentStep={currentSlide}
          stepsCount={props.slides.length}
          onStepClick={step => setCurrentSlide(step)}
        />
        <div className="relative flex flex-grow h-full overflow-hidden">
          {props.slides.map((slide, i) => (
            <motion.div
              key={i}
              className="absolute w-full h-full"
              style={{ left: `${i * 100}%` }}
              animate={{ x: `${-currentSlide * 100}%` }}
              transition={{ duration: 0.5, type: 'keyframes' }}
            >
              <ScrollableContainer>{slide}</ScrollableContainer>
            </motion.div>
          ))}
        </div>
      </div>

      {props.slides.length > 1 && (
        <Arrow
          direction="right"
          handleClick={nextSlide}
          disabled={currentSlide === props.slides.length - 1}
        />
      )}
    </div>
  );
};

export default SlideShow;

const Arrow = ({ direction, handleClick, disabled = false }) => (
  <div
    className={clsx(
      'absolute z-10 top-1/2 h-10 w-10 fill-white',
      direction === 'left' ? 'left-0' : 'right-0 rotate-180',
      disabled ? 'opacity-20 cursor-not-allowed ' : 'cursor-pointer '
    )}
    onClick={handleClick}
  >
    <AngleLeft />
  </div>
);
