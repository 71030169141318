import { useEffect } from 'react';
import { Route, Switch } from 'wouter';
import { IntlProvider } from 'react-intl';
import messages from './locales/en';
import { NestedRoutes } from './components/nested-routes';
import MeetingRoomPage from './pages/meeting-room';
import InboxPage from './pages/inbox';
import ProjectsPage from './pages/projects';
import PortfolioPage from './pages/portfolio';
import CostCentersPage from './pages/cost-centers';

export const App = () => {
  useEffect(() => {
    console.log(
      `[branch|commit]: [${process.env.CF_PAGES_BRANCH}|${process.env.GIT_COMMIT_HASH}]`
    );
  });

  return (
    <IntlProvider messages={messages} locale="en">
      <NestedRoutes base="/games">
        <Switch>
          <Route path="/:id/is-not-started" component={null} />
          <Route path="/:id/not-in-game" component={null} />
          <InGameRoutes />
        </Switch>
      </NestedRoutes>
    </IntlProvider>
  );
};

const InGameRoutes = () => {
  return (
    <Route path="/:id/:rest*">
      <Switch>
        <Route path="/:id" component={null} />
        <Route path="/:id/meeting-room" component={MeetingRoomPage} />
        <Route path="/:id/inbox" component={InboxPage} />
        <Route path="/:id/projects" component={ProjectsPage} />
        <Route path="/:id/portfolio" component={PortfolioPage} />
        <Route path="/:id/cost-centers" component={CostCentersPage} />

        <Route path="/:id/debriefs/:debriefId" component={null} />

        <Route path="/:rest*">
          {(params: any) =>
            `
            [404] Sorry,
            the page ${params.rest} does not exist !
            `
          }
        </Route>
      </Switch>
    </Route>
  );
};
