export default {
  'menu.meeting-room': 'Meeting Room',
  'menu.inbox': 'Inbox',
  'menu.projects': 'Projects',
  'menu.portfolio': 'Portfolio',
  'menu.cost-centers': 'Cost Centers',

  'inbox.title': 'Inbox',
  'projects.title': 'Projects List',
  'portfolio.title': 'Portfolio',
  'portfolio.description':
    'Here you can find all the <b>available projects</b>. Click on numbers to see the project name and description.{br}If you want to add (or remove) the project to your portfolio, click on the toggle on the right.',
  'portfolio.submit': 'Submit',
  'portfolio.submit.description':
    'If you feel confident, lorem ipsum dolor sit amet consectetur. Arcu at enim cras pulvinar iaculis odio enim. Vulputate accumsan aliquam mi sodales accumsan aliquam mi sodales ornare nulla quis.',
  'portfolio.submit.button': 'Submit your portfolio',
  'portfolio.get-help': 'Get help',
  'portfolio.get-help.consultancy': 'Consultancy Service',
  'portfolio.get-help.consultancy.description':
    'Send your portfolio to the consultancy service and wait for a reply. Lorem ipsum dolor sit amet consectetur. Arcu at enim cras pulvinar iaculis odio enim. Vulputate accumsan aliquam mi sodales ornare nulla quis.',
  'portfolio.get-help.consultancy.button': 'Review portfolio',
  'portfolio.get-help.call-expert': 'Call an expert',
  'portfolio.get-help.call-expert.description':
    'Send your portfolio to the consultancy service and wait for a reply. Lorem ipsum dolor sit amet consectetur. Arcu at enim cras pulvinar iaculis odio enim. Vulputate accumsan aliquam mi sodales ornare nulla quis.',
  'portfolio.get-help.call-expert.button': 'Request a meeting',
  'portfolio.submit.confirm.title': 'Portfolio submission',
  'portfolio.submit.confirm.description':
    'You will not be able to revoke this action',
  'portfolio.submit.confirm.consent': 'Yes, I want to submit my portfolio.',
  'portfolio.submit.confirm.button': 'Submit portfolio',
  'portfolio.submit.submission.title': 'Congratulations!',
  'portfolio.submit.submission.description.1':
    'Your Portfolio has been submitted correctly!',
  'portfolio.submit.submission.description.2':
    'You’ll receive the results in your InBox as soon as everyone has finished.',

  'portfolio.get-help.consultant.title': 'Confirm Consultancy',
  'portfolio.get-help.consultant.description':
    'Reviewing your portfolio will cost <b>{cost}</b> from the Financial budget.{br}{br}This is your <b>{attemptNumber}</b> attempt out of {totalAttempts}.',
  'portfolio.get-help.consultant.consent':
    'Yes, I want to review my portfolio.',
  'portfolio.get-help.consultant.button': 'Review portfolio',
  'portfolio.get-help.reviewed.title': 'Your portfolio is under review...',
  'portfolio.get-help.reviewed.description.1':
    'The Consultancy service will get back to you soon, you will receive the result of the consulting by mail.',
  'portfolio.get-help.reviewed.description.2': 'Check your InBox!',

  'costCenters.title': 'Cost Centers',
  'meeting-room.title': 'Meeting Room',
  'onboarding.title': 'Onboarding',

  selectProjects: 'Select Projects',
};
