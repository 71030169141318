import Lottie from 'react-lottie-player';
import t from '../../utils/t';
import Modal from '../modal';
import animation from '../../../lottie-animations/Lottie-coriandoli.json';

const SubmissionModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className="relative flex flex-col space-y-10 items-center justify-center text-white p-24 overflow-hidden">
      <Lottie
        animationData={animation}
        className="absolute w-2/3"
        speed={0.6}
        play
        loop
      />
      <h3 className="font-bold text-4xl">
        {t('portfolio.submit.submission.title')}
      </h3>
      <p className="font-bold">
        {t('portfolio.submit.submission.description.1')}
      </p>
      <p className="font-thin">
        {t('portfolio.submit.submission.description.2')}
      </p>
    </div>
  </Modal>
);

export default SubmissionModal;
