import CrossSmall from '../assets/icons/cross-small';

const Modal = ({ children, isOpen, onClose }) =>
  isOpen ? (
    <div
      className="fixed z-50 top-0 left-0 h-screen w-screen bg-black/50 flex items-center justify-center"
      onClick={onClose}
    >
      <div
        className="bg-accountingBlue-light rounded-2xl p-6 border border-accountingGreen-base"
        onClick={e => e.stopPropagation()}
      >
        <div
          className="cursor-pointer fill-white float-right hover:bg-accountingBlue-dark rounded-full w-8 h-8 p-1"
          onClick={onClose}
        >
          <CrossSmall />
        </div>
        {children}
      </div>
    </div>
  ) : null;

export default Modal;
