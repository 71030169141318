import { ReactNode, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useOverflow } from '../hooks/useOverflow.hook';
import { useScrollRef } from '../hooks/useScrollRef.hook';
import { useTheme } from '../stores/theme';
import { useLocation } from 'wouter';

interface IProps {
  children: JSX.Element | JSX.Element[] | ReactNode;
}

const ScrollableContainer = ({ children }: IProps) => {
  const [location] = useLocation();
  const images = useTheme(({ images }) => images);

  const scrollableRef = useRef<HTMLDivElement>(null);

  const scrollProgress: number = useScrollRef(scrollableRef);
  const isOverflowing: boolean = useOverflow(scrollableRef);

  useEffect(() => {
    setTimeout(() => {
      if (!scrollableRef?.current) return;

      scrollableRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }, 5);
  }, [location]);

  return (
    <div className="relative h-full overflow-hidden">
      {/* <span
        className="absolute top-0 z-10 w-full h-20 transition-all duration-200 ease-in-out pointer-events-none"
        style={{
          backgroundImage: 'linear-gradient(to bottom, #8d8d8d, Transparent)',
          opacity: isOverflowing ? (scrollProgress > 1 ? 1 : 0) : 0,
        }}
      />
      <span
        className="absolute bottom-0 z-10 w-full h-20 transition-all duration-200 ease-in-out pointer-events-none"
        style={{
          backgroundImage: 'linear-gradient(to top, #8d8d8d, Transparent)',
          opacity: isOverflowing ? (scrollProgress < 99 ? 1 : 0) : 0,
        }}
      />

      <motion.span
        className="absolute z-40 w-12 h-12 pointer-events-none select-none right-2 bottom-2 text-rialtoBlack"
        style={{
          opacity: isOverflowing ? (scrollProgress < 99 ? 0.8 : 0) : 0,
        }}
        animate={{
          y: [0, -4, 0, 4, 0],
        }}
        transition={{
          repeat: Infinity,
          duration: 0.9,
          ease: 'linear',
        }}
        title="Scroll this container to show more content"
      >
        <img
          src={images('scroll-indicator.svg')}
          alt="Scroll indicator"
          className="h-full opacity-80"
        />
      </motion.span> */}
      <div
        ref={scrollableRef}
        className="w-full h-full overflow-y-auto scrollbar-thin" // pb-10 xl:pb-20
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollableContainer;
