import { useTheme } from '../../stores/theme';
import buildAssetPath from '../../utils/build-asset-path';
import GoalBadge from '../goal-badge';

type Props = {
  message: {
    avatar?: string;
    sender: string;
    subject: string;
    content: React.ReactNode;
    goal?: string;
  };
};

const Message = ({ message }: Props) => {
  const images = useTheme(({ images }) => images);

  return (
    <div className="h-full rounded-lg bg-accountingBlue-light text-white">
      <div className="flex items-center justify-between border-b-4 border-accountingBlue-base py-4 px-8">
        <div className="flex space-x-4 items-center">
          <div className="h-9 w-9 rounded-full bg-accountingGreen-light">
            {message.avatar && (
              <img src={images(buildAssetPath(message.avatar))} />
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-2xl font-bold leading-none">
              {message.sender}
            </span>
            <span className="leading-none">{message.subject}</span>
          </div>
        </div>

        {message.goal && <GoalBadge goal={message.goal} />}
      </div>
      <div className="py-4 px-8">{message.content}</div>
    </div>
  );
};

export default Message;
