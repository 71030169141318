import create, { GetState, SetState, UseBoundStore } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import * as Theme from './factories/theme';
import * as Logger from './factories/logger';

type StoreType = ReturnType<typeof createRootSlice>;

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Theme.create(set, get),
  ...Logger.create(set, get),
});

export const useTheme: UseBoundStore<StoreType> &
  StoreApiWithPersist<StoreType> = create(
  persist(createRootSlice, {
    name: 'accounting-theme',
  })
);
