import { useState } from 'react';
import clsx from 'clsx';
import t from '../../utils/t';
import Button from '../button';
import ConsultantModal from './consultant-modal';
import ReviewModal from './review-modal';
import ConsultancyAttempts from './consultancy-attempts';

const GetHelp = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false); // TODO replace this with the real logic

  return (
    <div className="bg-accountingBlue-light p-4 rounded-2xl">
      <ConsultantModal
        isOpen={submitting}
        onClose={() => setSubmitting(false)}
        onConfirm={() => {
          setSubmitting(false);
          setSubmitted(true);
        }}
      />
      <ReviewModal isOpen={submitted} onClose={() => setSubmitted(false)} />
      <h3 className="font-semibold text-lg uppercase text-accountingGreen-light">
        {t('portfolio.get-help')}
      </h3>
      <Divider dashed />
      <p className="font-thin leading-normal my-4">
        {t('portfolio.get-help.consultancy')}
      </p>
      <p className="font-thin leading-normal my-4">
        {t('portfolio.get-help.consultancy.description')}
      </p>
      <div className="flex flex-col space-y-4">
        <ConsultancyAttempts attemptsLeft={2} totalAttempts={3} />
        <Button
          variant="outline"
          className="self-end"
          onClick={() => setSubmitting(true)}
        >
          {t('portfolio.get-help.consultancy.button')}
        </Button>
      </div>
      <Divider dashed />
      <p className="font-thin leading-normal my-4">
        {t('portfolio.get-help.call-expert')}
      </p>
      <p className="font-thin leading-normal my-4">
        {t('portfolio.get-help.call-expert.description')}
      </p>
      <Button variant="outline">
        {t('portfolio.get-help.call-expert.button')}
      </Button>
    </div>
  );
};

export default GetHelp;

const Divider = ({ dashed = false }) => (
  <hr
    className={clsx(
      'my-4 border border-accountingGray-light',
      dashed && 'border-dashed'
    )}
  />
);
