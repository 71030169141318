import Flag from '../../assets/icons/flag';
import t from '../../utils/t';
import Button from '../button';
import ProjectsPicker from '../projects-picker';
import GetHelp from './get-help';
import Submit from './submit';

const Portfolio = () => {
  return (
    <div className="flex flex-col space-y-4 px-4">
      <p className="font-thin leading-normal my-4">
        {t('portfolio.description')}
      </p>
      <ProjectsPicker
        className="-mx-4 rounded-lg bg-transparent border border-accountingGreen-base p-6"
        expandable={false}
      />
      <div className="flex space-x-14">
        <div className="w-1/3 mt-8">
          <Submit />
        </div>
        <div className="w-2/3 mt-8">
          <GetHelp />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
