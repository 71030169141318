import Flag from '../../assets/icons/flag';
import t from '../../utils/t';
import Button from '../button';
import Modal from '../modal';

const SubmitModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col space-y-4 items-center justify-center text-white py-6 px-24">
      <Flag className="h-8 w-8 fill-accountingGreen-light" />
      <h3 className="font-bold text-xl">
        {t('portfolio.submit.confirm.title')}
      </h3>
      <p className="font-thin">{t('portfolio.submit.confirm.description')}</p>
      <p className="pt-12 pb-6">{t('portfolio.submit.confirm.consent')}</p>
      <Button variant="solid" onClick={onConfirm}>
        {t('portfolio.submit.confirm.button')}
      </Button>
    </div>
  </Modal>
);

export default SubmitModal;
