import clsx from 'clsx';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  variant?: 'solid' | 'outline';
};

const Button = (props: Props) => (
  <div
    {...props}
    className={clsx(
      'flex items-center space-x-2 text-base font-bold uppercase px-4 py-2 rounded-lg cursor-pointer hover:scale-105 transform transition-transform duration-200 w-max',
      props.variant === 'solid'
        ? 'bg-accountingGreen-light text-accountingBlue-dark'
        : 'border border-accountingGreen-light text-accountingGreen-light',
      props.className
    )}
  />
);

export default Button;
