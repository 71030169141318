import { useIntl } from 'react-intl';
import { InGameLayout } from '../layout/in-game-layout';
import { Menu } from '../components/menu';
import MainContent from '../layout/main-content';
import MeetingRoom from '../components/meeting-room';

const MeetingRoomPage = () => {
  const { formatMessage } = useIntl();
  return (
    <InGameLayout side={<Menu />}>
      <MainContent
        title={formatMessage({ id: 'meeting-room.title' })}
        hideFrame
      >
        <MeetingRoom />
      </MainContent>
    </InGameLayout>
  );
};

export default MeetingRoomPage;
