import Button from '../../button';

export default [
  <div className="text-white h-full flex flex-col space-y-2 items-center justify-center text-4xl">
    Slide 1
  </div>,
  <div className="text-white h-full">
    Cos’è Lorem Ipsum? Lorem Ipsum è un testo segnaposto utilizzato nel settore
    della tipografia e della stampa. Lorem Ipsum è considerato il testo
    segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo
    prese una cassetta di caratteri e li assemblò per preparare un testo
    campione. È sopravvissuto non solo a più di cinque secoli, ma anche al
    passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato.
    Fu reso popolare, negli anni ’60, con la diffusione dei fogli di caratteri
    trasferibili “Letraset”, che contenevano passaggi del Lorem Ipsum, e più
    recentemente da software di impaginazione come Aldus PageMaker, che
    includeva versioni del Lorem Ipsum. Perchè lo utilizziamo? È universalmente
    riconosciuto che un lettore che osserva il layout di una pagina viene
    distratto dal contenuto testuale se questo è leggibile. Lo scopo
    dell’utilizzo del Lorem Ipsum è che offre una normale distribuzione delle
    lettere (al contrario di quanto avviene se si utilizzano brevi frasi
    ripetute, ad esempio “testo qui”), apparendo come un normale blocco di testo
    leggibile. Molti software di impaginazione e di web design utilizzano Lorem
    Ipsum come testo modello. Molte versioni del testo sono state prodotte negli
    anni, a volte casualmente, a volte di proposito (ad esempio inserendo
    passaggi ironici). Da dove viene? Al contrario di quanto si pensi, Lorem
    Ipsum non è semplicemente una sequenza casuale di caratteri. Risale ad un
    classico della letteratura latina del 45 AC, cosa che lo rende vecchio di
    2000 anni. Richard McClintock, professore di latino al Hampden-Sydney
    College in Virginia, ha ricercato una delle più oscure parole latine,
    consectetur, da un passaggio del Lorem Ipsum e ha scoperto tra i vari testi
    in cui è citata, la fonte da cui è tratto il testo, le sezioni 1.10.32 and
    1.10.33 del "de Finibus Bonorum et Malorum" di Cicerone. Questo testo è un
    trattato su teorie di etica, molto popolare nel Rinascimento. La prima riga
    del Lorem Ipsum, "Lorem ipsum dolor sit amet..", è tratta da un passaggio
    della sezione 1.10.32. Il brano standard del Lorem Ipsum usato sin dal
    sedicesimo secolo è riprodotto qui di seguito per coloro che fossero
    interessati. Anche le sezioni 1.10.32 e 1.10.33 del "de Finibus Bonorum et
    Malorum" di Cicerone sono riprodotte nella loro forma originale,
    accompagnate dalla traduzione inglese del 1914 di H. Rackham. Dove posso
    trovarlo? Esistono innumerevoli variazioni dei passaggi del Lorem Ipsum, ma
    la maggior parte hanno subito delle variazioni del tempo, a causa
    dell’inserimento di passaggi ironici, o di sequenze casuali di caratteri
    palesemente poco verosimili. Se si decide di utilizzare un passaggio del
    Lorem Ipsum, è bene essere certi che non contenga nulla di imbarazzante. In
    genere, i generatori di testo segnaposto disponibili su internet tendono a
    ripetere paragrafi predefiniti, rendendo questo il primo vero generatore
    automatico su intenet. Infatti utilizza un dizionario di oltre 200 vocaboli
    latini, combinati con un insieme di modelli di strutture di periodi, per
    generare passaggi di testo verosimili. Il testo così generato è sempre privo
    di ripetizioni, parole imbarazzanti o fuori luogo ecc. Cos’è Lorem Ipsum?
    Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e
    della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal
    sedicesimo secolo, quando un anonimo tipografo prese una cassetta di
    caratteri e li assemblò per preparare un testo campione. È sopravvissuto non
    solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione,
    pervenendoci sostanzialmente inalterato. Fu reso popolare, negli anni ’60,
    con la diffusione dei fogli di caratteri trasferibili “Letraset”, che
    contenevano passaggi del Lorem Ipsum, e più recentemente da software di
    impaginazione come Aldus PageMaker, che includeva versioni del Lorem Ipsum.
    Perchè lo utilizziamo? È universalmente riconosciuto che un lettore che
    osserva il layout di una pagina viene distratto dal contenuto testuale se
    questo è leggibile. Lo scopo dell’utilizzo del Lorem Ipsum è che offre una
    normale distribuzione delle lettere (al contrario di quanto avviene se si
    utilizzano brevi frasi ripetute, ad esempio “testo qui”), apparendo come un
    normale blocco di testo leggibile. Molti software di impaginazione e di web
    design utilizzano Lorem Ipsum come testo modello. Molte versioni del testo
    sono state prodotte negli anni, a volte casualmente, a volte di proposito
    (ad esempio inserendo passaggi ironici). Da dove viene? Al contrario di
    quanto si pensi, Lorem Ipsum non è semplicemente una sequenza casuale di
    caratteri. Risale ad un classico della letteratura latina del 45 AC, cosa
    che lo rende vecchio di 2000 anni. Richard McClintock, professore di latino
    al Hampden-Sydney College in Virginia, ha ricercato una delle più oscure
    parole latine, consectetur, da un passaggio del Lorem Ipsum e ha scoperto
    tra i vari testi in cui è citata, la fonte da cui è tratto il testo, le
    sezioni 1.10.32 and 1.10.33 del "de Finibus Bonorum et Malorum" di Cicerone.
    Questo testo è un trattato su teorie di etica, molto popolare nel
    Rinascimento. La prima riga del Lorem Ipsum, "Lorem ipsum dolor sit amet..",
    è tratta da un passaggio della sezione 1.10.32. Il brano standard del Lorem
    Ipsum usato sin dal sedicesimo secolo è riprodotto qui di seguito per coloro
    che fossero interessati. Anche le sezioni 1.10.32 e 1.10.33 del "de Finibus
    Bonorum et Malorum" di Cicerone sono riprodotte nella loro forma originale,
    accompagnate dalla traduzione inglese del 1914 di H. Rackham. Dove posso
    trovarlo? Esistono innumerevoli variazioni dei passaggi del Lorem Ipsum, ma
    la maggior parte hanno subito delle variazioni del tempo, a causa
    dell’inserimento di passaggi ironici, o di sequenze casuali di caratteri
    palesemente poco verosimili. Se si decide di utilizzare un passaggio del
    Lorem Ipsum, è bene essere certi che non contenga nulla di imbarazzante. In
    genere, i generatori di testo segnaposto disponibili su internet tendono a
    ripetere paragrafi predefiniti, rendendo questo il primo vero generatore
    automatico su intenet. Infatti utilizza un dizionario di oltre 200 vocaboli
    latini, combinati con un insieme di modelli di strutture di periodi, per
    generare passaggi di testo verosimili. Il testo così generato è sempre privo
    di ripetizioni, parole imbarazzanti o fuori luogo ecc. Cos’è Lorem Ipsum?
    Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e
    della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal
    sedicesimo secolo, quando un anonimo tipografo prese una cassetta di
    caratteri e li assemblò per preparare un testo campione. È sopravvissuto non
    solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione,
    pervenendoci sostanzialmente inalterato. Fu reso popolare, negli anni ’60,
    con la diffusione dei fogli di caratteri trasferibili “Letraset”, che
    contenevano passaggi del Lorem Ipsum, e più recentemente da software di
    impaginazione come Aldus PageMaker, che includeva versioni del Lorem Ipsum.
    Perchè lo utilizziamo? È universalmente riconosciuto che un lettore che
    osserva il layout di una pagina viene distratto dal contenuto testuale se
    questo è leggibile. Lo scopo dell’utilizzo del Lorem Ipsum è che offre una
    normale distribuzione delle lettere (al contrario di quanto avviene se si
    utilizzano brevi frasi ripetute, ad esempio “testo qui”), apparendo come un
    normale blocco di testo leggibile. Molti software di impaginazione e di web
    design utilizzano Lorem Ipsum come testo modello. Molte versioni del testo
    sono state prodotte negli anni, a volte casualmente, a volte di proposito
    (ad esempio inserendo passaggi ironici). Da dove viene? Al contrario di
    quanto si pensi, Lorem Ipsum non è semplicemente una sequenza casuale di
    caratteri. Risale ad un classico della letteratura latina del 45 AC, cosa
    che lo rende vecchio di 2000 anni. Richard McClintock, professore di latino
    al Hampden-Sydney College in Virginia, ha ricercato una delle più oscure
    parole latine, consectetur, da un passaggio del Lorem Ipsum e ha scoperto
    tra i vari testi in cui è citata, la fonte da cui è tratto il testo, le
    sezioni 1.10.32 and 1.10.33 del "de Finibus Bonorum et Malorum" di Cicerone.
    Questo testo è un trattato su teorie di etica, molto popolare nel
    Rinascimento. La prima riga del Lorem Ipsum, "Lorem ipsum dolor sit amet..",
    è tratta da un passaggio della sezione 1.10.32. Il brano standard del Lorem
    Ipsum usato sin dal sedicesimo secolo è riprodotto qui di seguito per coloro
    che fossero interessati. Anche le sezioni 1.10.32 e 1.10.33 del "de Finibus
    Bonorum et Malorum" di Cicerone sono riprodotte nella loro forma originale,
    accompagnate dalla traduzione inglese del 1914 di H. Rackham. Dove posso
    trovarlo? Esistono innumerevoli variazioni dei passaggi del Lorem Ipsum, ma
    la maggior parte hanno subito delle variazioni del tempo, a causa
    dell’inserimento di passaggi ironici, o di sequenze casuali di caratteri
    palesemente poco verosimili. Se si decide di utilizzare un passaggio del
    Lorem Ipsum, è bene essere certi che non contenga nulla di imbarazzante. In
    genere, i generatori di testo segnaposto disponibili su internet tendono a
    ripetere paragrafi predefiniti, rendendo questo il primo vero generatore
    automatico su intenet. Infatti utilizza un dizionario di oltre 200 vocaboli
    latini, combinati con un insieme di modelli di strutture di periodi, per
    generare passaggi di testo verosimili. Il testo così generato è sempre privo
    di ripetizioni, parole imbarazzanti o fuori luogo ecc.
  </div>,
  <div className="text-white h-full flex flex-col space-y-2 items-center justify-center text-4xl">
    Slide 3
  </div>,
  <div className="text-white h-full flex flex-col space-y-2 items-center justify-center text-4xl">
    Slide 4
  </div>,
  <div className="text-white h-full flex flex-col space-y-2 items-center justify-center text-4xl">
    Slide 5
  </div>,
  <div className="text-white h-full flex flex-col space-y-2 items-center justify-center text-4xl">
    Slide 6
    <Button variant="solid" onClick={() => alert('Close onboarding!')}>
      Let's start!
    </Button>
  </div>,
];
