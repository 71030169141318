import { useState } from 'react';
import ScrollableContainer from '../scrollable-container';
import ProjectCard from './project-card';

const Projects = () => {
  const [starredProjects, setStarredProjects] = useState([]);

  return (
    <ScrollableContainer>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 px-8 py-4">
        {projects(starredProjects).map(project => (
          <ProjectCard
            key={project.id}
            project={project}
            toggleStarred={() =>
              setStarredProjects(
                starredProjects.includes(project.id)
                  ? starredProjects.filter(id => id !== project.id)
                  : [...starredProjects, project.id]
              )
            }
          />
        ))}
      </div>
    </ScrollableContainer>
  );
};

export default Projects;

const projects = (starredProjects: string[]) => {
  const ps = [];
  for (let i = 1; i <= 30; i++) {
    ps.push({
      id: i.toString(),
      number: i,
      title: `Project ${i}`,
      description: `Description ${i}: Lorem ipsum dolor sit amet consectetur. Diam morbi amet posuere duis. Cum molestie rhoncus gravida imperdiet aenean curabitur. Quis diam egestas curabitur dolor felis dolor bibendum. Viverra feugiat velit magna consectetur quis orci eu bibendum. Viverra feugiat velit magna consectetur quis orci eu.`,
      isStarred: starredProjects.includes(i.toString()),
      attachment: 'prova.pdf',
    });
  }
  return ps;
};
