import Comment from '../../assets/icons/comment';
import Microphone from '../../assets/icons/microphone';
import Phone from '../../assets/icons/phone';
import VideoCamera from '../../assets/icons/video-camera-2';

const MeetingControls = () => (
  <div className="flex space-x-2 justify-center items-center w-full pt-4 fill-accountingGreen-light">
    <Comment className="h-7 w-7 rounded-full border border-accountingGreen-light p-1" />
    <VideoCamera className="h-7 w-7 rounded-full border border-accountingGreen-light p-1" />
    <Microphone className="h-7 w-7 rounded-full border border-accountingGreen-light p-1" />
    <Phone className="h-7 w-7 rounded-full border border-accountingGreen-light p-1" />
  </div>
);

export default MeetingControls;
