import clsx from 'clsx';
import Download from '../../assets/icons/download';
import Star from '../../assets/icons/star';

type Project = {
  id: string;
  number: number;
  title: string;
  description: string;
  isStarred: boolean;
  attachment?: string;
};

type Props = {
  project: Project;
  toggleStarred: (id: string) => void;
};

const ProjectCard = ({ project, toggleStarred }: Props) => (
  <div className="flex items-center bg-accountingBlue-light rounded-2xl p-6">
    <div className="w-1/5 flex flex-col space-y-6">
      <span className="flex text-2xl items-center justify-center w-12 h-12 p-1 font-bold text-accountingBlue-dark bg-accountingGreen-base rounded-full">
        {project.number}
      </span>
      <span
        className={clsx(
          'w-12 h-12 p-3 flex items-center justify-center rounded-full border-2 cursor-pointer',
          project.isStarred
            ? 'border-accountingGreen-base fill-accountingGreen-base'
            : 'hover:border-accountingGreen-base hover:fill-accountingGreen-base border-accountingGray-base fill-accountingGray-base'
        )}
        onClick={() => toggleStarred(project.id)}
      >
        <Star />
      </span>
      <span
        className={clsx(
          'w-12 h-12 p-3 flex items-center justify-center rounded-full border-2 border-accountingGray-base fill-accountingGray-base',
          project.attachment
            ? 'hover:border-accountingGreen-base hover:fill-accountingGreen-base cursor-pointer'
            : 'cursor-not-allowed'
        )}
      >
        <Download />
      </span>
    </div>
    <div className="w-4/5 h-full overflow-hidden flex flex-col space-y-4">
      <span className="font-semibold uppercase text-2xl">{project.title}</span>
      <span className="text-accountingGray-light leading-snug">
        {project.description}
      </span>
    </div>
  </div>
);

export default ProjectCard;
