import clsx from 'clsx';
import Glasses from '../../assets/icons/glasses';

const ConsultancyAttempts = ({ totalAttempts, attemptsLeft }) => {
  return (
    <div className="flex space-x-8 pt-8 pl-4">
      {new Array(totalAttempts).fill(null).map((_, index) => (
        <div
          key={index}
          className={clsx(
            'rounded-lg border p-3 rotate-45 bg-accountingBlue-base',
            index + 1 <= attemptsLeft
              ? 'border-accountingGreen-light'
              : 'border-accountingBlue-dark'
          )}
          style={{
            boxShadow:
              index + 1 <= attemptsLeft
                ? 'none'
                : 'inset 0px 4px 4px rgba(0, 0, 0, 0.4)',
          }}
        >
          <Glasses
            className={clsx(
              'h-6 w-6 -rotate-45',
              index + 1 <= attemptsLeft
                ? 'fill-accountingGreen-light'
                : 'fill-accountingBlue-light'
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default ConsultancyAttempts;
