import clsx from 'clsx';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import CrossSmall from '../../assets/icons/cross-small';
import Interrogation from '../../assets/icons/interrogation';
import SlideShow from '../slideshow';
import slides from './slides';

const Onboarding = ({ active = false }) => {
  const { formatMessage } = useIntl();
  const [isOnboardingActive, setIsOnboardingActive] = useState(active);

  return (
    <>
      <div
        className={clsx(
          'self-start cursor-pointer p-2 rounded-full aspect-square',
          isOnboardingActive
            ? 'bg-accountingBlue-dark'
            : 'bg-accountingBlue-light hover:bg-accountingBlue-dark'
        )}
        onClick={() => setIsOnboardingActive(!isOnboardingActive)}
      >
        <div className="w-6 h-6 fill-white rounded-full">
          <Interrogation />
        </div>
      </div>
      {isOnboardingActive && (
        <div
          className="absolute flex justify-end top-0 bottom-0 w-full left-0 z-10 bg-black/50 p-8"
          onClick={() => setIsOnboardingActive(false)}
        >
          {/* <span className="cursor-pointer absolute left-6 bottom-10 w-9 h-9 fill-white rounded-full bg-accountingBlue-light p-1 hover:bg-accountingBlue-dark">
            <Interrogation />
          </span> */}
          <div
            className="relative w-11/12 bg-accountingBlue-base rounded-2xl p-8"
            onClick={e => e.stopPropagation()}
          >
            <div
              className="cursor-pointer fill-white float-right hover:bg-accountingBlue-dark rounded-full w-8 h-8 p-1"
              onClick={() => setIsOnboardingActive(false)}
            >
              <CrossSmall />
            </div>
            <div className="h-full w-full pb-10">
              <h1 className="text-2xl font-bold text-left text-accountingGreen-light uppercase">
                {formatMessage({ id: 'onboarding.title' })}
              </h1>
              <SlideShow slides={slides} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Onboarding;
