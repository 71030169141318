import Lottie from 'react-lottie-player';
import t from '../../utils/t';
import Modal from '../modal';
import animation from '../../../lottie-animations/Lottie-conferma.json';

const ReviewModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className="relative flex flex-col space-y-10 items-center justify-center text-white p-24 overflow-hidden">
      <h3 className="font-bold text-4xl">
        {t('portfolio.get-help.reviewed.title')}
      </h3>
      <Lottie
        animationData={animation}
        className="w-1/3"
        speed={0.4}
        play
        loop={false}
        onComplete={onClose}
      />

      <p className="font-thin">
        {t('portfolio.get-help.reviewed.description.1')}
      </p>
      <p className="font-bold">
        {t('portfolio.get-help.reviewed.description.2')}
      </p>
    </div>
  </Modal>
);

export default ReviewModal;
