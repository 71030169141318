import { useState } from 'react';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import ProjectToggle from './project-toggle';

const ProjectsPicker = ({ expandable = true, className = '' }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(!expandable);
  const [selectedProject, setSelectedProject] = useState<number>();
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  return (
    <motion.div
      initial={{
        height: '0vh',
      }}
      animate={{
        height: isOpen ? 'auto' : '0vh',
      }}
      className={clsx(
        'w-full rounded-b-lg text-white bg-accountingBlue-light',
        className
      )}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        className="h-full flex items-start p-4 space-x-8"
      >
        <div className="w-1/3">
          <h4 className="m-2 font-semibold text-sm">Projects Portfolio</h4>
          <div className="flex flex-wrap">
            {new Array(30).fill(0).map((_, i) => (
              <span
                key={i}
                className={clsx(
                  'h-8 w-8 flex items-center font-semibold rounded-full aspect-square p-2 text-sm leading-none m-2 justify-center cursor-pointer opacity-50 hover:opacity-100',
                  selectedProjects.includes(i)
                    ? 'bg-accountingGreen-base text-accountingBlue-base'
                    : 'border border-white',
                  selectedProject === i && 'opacity-100'
                )}
                onClick={() => setSelectedProject(i)}
              >
                {i + 1}
              </span>
            ))}
          </div>
        </div>
        <div className="w-2/3">
          <h4 className="m-2 font-semibold text-sm">Project Description</h4>
          {selectedProject >= 0 && (
            <div className="m-2 flex space-x-4">
              <ProjectToggle
                isActive={selectedProjects.includes(selectedProject)}
                projectNumber={selectedProject + 1}
                onToggle={() =>
                  selectedProjects.includes(selectedProject)
                    ? setSelectedProjects(
                        selectedProjects.filter(a => a !== selectedProject)
                      )
                    : setSelectedProjects([
                        ...selectedProjects,
                        selectedProject,
                      ])
                }
              />
              <div>
                <p className="font-bold text-lg">
                  Project #{selectedProject + 1} Title
                </p>
                <div className="mt-2 font-extralight">
                  Lorem ipsum dolor sit amet consectetur. Feugiat lectus mi
                  ultricies sollicitudin eget lectus sed. In augue libero elit
                  in diam accumsan nibh bibendum magna. Ut massa ultrices enim
                  augue vitae libero. Sodales feugiat non quis faucibus risus
                  orci tristique.
                </div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
      {expandable && (
        <div className="absolute -bottom-10 left-0 right-0">
          <div
            className="cursor-pointer text-xs px-4 py-3 mx-auto rounded-b-lg bg-accountingBlue-light w-max flex items-center leading-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span
              className={clsx(
                'text-xl transform mx-2 leading-none',
                isOpen ? 'rotate-90' : '-rotate-90'
              )}
            >
              &lsaquo;
            </span>
            {formatMessage({ id: 'selectProjects' })}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default ProjectsPicker;
