import { useMemo } from 'react';
import { useRoute } from 'wouter';
import { NavigationBar } from '../../layout/navigation-bar';
import NavButton from './nav-button';
import Onboarding from '../onboarding';
import Apps from '../../assets/icons/apps';
import Briefcase from '../../assets/icons/briefcase';
import CommentAlt from '../../assets/icons/comment-alt';
import Envelope from '../../assets/icons/envelope';
import PaperPlane from '../../assets/icons/paper-plane';

export const Menu = () => {
  const [_match, params] = useRoute<GameRouteParams>('/:gamePlayId/:rest*');

  const menuItems = useMemo(
    () => [
      {
        label: 'menu.meeting-room',
        link: `meeting-room`,
        icon: <CommentAlt />,
        disabled: false,
      },
      {
        label: 'menu.inbox',
        link: `inbox`,
        icon: <Envelope />,
        disabled: false,
      },
      {
        label: 'menu.projects',
        link: `projects`,
        icon: <Apps />,
        disabled: false,
      },
      {
        label: 'menu.portfolio',
        link: 'portfolio',
        icon: <Briefcase />,
        disabled: false,
      },
      {
        label: 'menu.cost-centers',
        link: 'cost-centers',
        icon: <PaperPlane />,
        disabled: false,
      },
    ],
    [params]
  );

  return (
    <NavigationBar isActive>
      <div className="flex flex-col items-center w-full h-full space-y-4">
        <div className="text-white mb-8 leading-relaxed text-xl font-bold">
          Team #12
        </div>
        {menuItems.map(menuItem => (
          <NavButton
            key={menuItem.label}
            {...menuItem}
            link={`/${params.gamePlayId}/${menuItem.link}`}
            isActive={params.rest === menuItem.link}
          />
        ))}
      </div>
      <Onboarding />
    </NavigationBar>
  );
};

type GameRouteParams = {
  gamePlayId: string;
  rest: string;
};
