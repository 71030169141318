import { useState } from 'react';
import Flag from '../../assets/icons/flag';
import t from '../../utils/t';
import Button from '../button';
import Modal from '../modal';
import SubmissionModal from './submission-modal';
import SubmitModal from './submit-modal';

const Submit = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false); // TODO replace this with the real logic

  return (
    <>
      <SubmitModal
        isOpen={submitting}
        onClose={() => setSubmitting(false)}
        onConfirm={() => {
          setSubmitting(false);
          setSubmitted(true);
        }}
      />
      <SubmissionModal isOpen={submitted} onClose={() => setSubmitted(false)} />
      <h3 className="font-semibold text-lg uppercase text-accountingGreen-light">
        {t('portfolio.submit')}
      </h3>
      <p className="font-thin leading-normal my-4">
        {t('portfolio.submit.description')}
      </p>
      <Button variant="solid" onClick={() => setSubmitting(true)}>
        <Flag className="w-5 h-5 fill-accountingBlue-dark" />
        <span>{t('portfolio.submit.button')}</span>
      </Button>
    </>
  );
};

export default Submit;
