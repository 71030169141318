import { useIntl } from 'react-intl';
import hash from 'object-hash';

export default function t(id: string, values: Record<string, string> = {}) {
  const { formatMessage } = useIntl();

  return formatMessage(
    { id },
    Object.assign({}, values, {
      br: <br />,
      p: (...chunks) => <p key={hash(chunks)}>{chunks}</p>,
      b: (...chunks) => <strong key={hash(chunks)}>{chunks}</strong>,
      em: (...chunks) => <em key={hash(chunks)}>{chunks}</em>,
    })
  );
}
