import clsx from 'clsx';

const RoleAvatarPreview = ({ avatar, name, className = '', handleClick }) => (
  <div
    className={clsx(
      'h-full flex-grow border-accountingGreen-light bg-center bg-contain bg-no-repeat',
      className
    )}
    style={{
      backgroundPositionY: '30px',
      backgroundImage: `url('${avatar}')`,
    }}
    onClick={handleClick}
  >
    <span className="relative left-3 top-3 bg-accountingBlue-dark bg-opacity-90 text-white text-sm px-3 py-1 rounded-lg">
      {name}
    </span>
  </div>
);

export default RoleAvatarPreview;
