import { Link } from 'wouter';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

interface IProps {
  label: string;
  link?: string;
  icon: React.ReactNode;
  isActive: boolean;
  disabled?: boolean;
  handleClick?: () => void;
}

const NavButton = ({
  label,
  link,
  icon,
  isActive,
  disabled = false,
  handleClick,
}: IProps) => {
  const { formatMessage } = useIntl();
  const v = (
    <span
      className={clsx(
        'group w-full flex items-center rounded-xl p-3 transition-all duration-200 ease-in-out cursor-pointer text-white hover:bg-accountingBlue-dark',
        isActive
          ? 'bg-accountingBlue-dark'
          : !disabled
          ? 'bg-accountingBlue-light'
          : 'cursor-not-allowed'
      )}
      onClick={handleClick}
    >
      <span
        className={clsx(
          'w-6 h-6 mr-2',
          isActive
            ? 'fill-accountingGreen-light'
            : 'fill-white group-hover:fill-accountingGreen-light'
        )}
      >
        {icon}
      </span>
      <span className="text-sm font-semibold">
        {formatMessage({ id: label })}
      </span>
    </span>
  );

  return disabled || !link ? v : <Link href={link}>{v}</Link>;
};
export default NavButton;
