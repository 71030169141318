import { useIntl } from 'react-intl';
import { InGameLayout } from '../layout/in-game-layout';
import { Menu } from '../components/menu';
import MainContent from '../layout/main-content';
import Projects from '../components/projects';

const ProjectsPage = () => {
  const { formatMessage } = useIntl();
  return (
    <InGameLayout side={<Menu />}>
      <MainContent title={formatMessage({ id: 'projects.title' })}>
        <Projects />
      </MainContent>
    </InGameLayout>
  );
};

export default ProjectsPage;
