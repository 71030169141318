import clsx from 'clsx';
import { motion } from 'framer-motion';

const ProjectToggle = ({ isActive, projectNumber, onToggle }) => (
  <div
    className={clsx(
      'flex cursor-pointer rounded-3xl w-[80px] h-6 border',
      isActive
        ? 'border-accountingGreen-base bg-accountingGreen-base bg-opacity-20'
        : 'border-white'
    )}
  >
    <motion.div
      className={clsx(
        'relative flex items-center -top-1 justify-center text-sm font-semibold rounded-full w-8 h-8 text-semibold hover:scale-105 transform transition-transform duration-200',
        isActive
          ? 'text-accountingBlue-base bg-accountingGreen-base'
          : 'border border-white bg-accountingBlue-base'
      )}
      initial={{
        left: isActive ? '40%' : '-10%',
      }}
      animate={{ left: isActive ? '40%' : '-10%' }}
      onClick={onToggle}
    >
      {projectNumber}
    </motion.div>
  </div>
);

export default ProjectToggle;
