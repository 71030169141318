import clsx from 'clsx';

const MainContent = ({
  title,
  children,
  hideFrame = false,
  extra = null,
  controls = null,
}) => (
  <div className="relative flex flex-col w-full h-full p-8">
    {extra}
    <div className="flex w-full items-center justify-between text-2xl leading-tight tracking-widest font-semibold text-accountingGreen-light uppercase mb-4 ml-4 pr-8">
      <span>{title}</span>
      {controls && <div className="">{controls}</div>}
    </div>
    <div
      className={clsx(
        'flex-grow text-white overflow-hidden',
        !hideFrame && 'border rounded-lg border-accountingGreen-base p-4'
      )}
    >
      {children}
    </div>
  </div>
);

export default MainContent;
