import t from '../../utils/t';
import Button from '../button';
import Modal from '../modal';
import ConsultancyAttempts from './consultancy-attempts';

const ConsultantModal = ({ isOpen, onClose, onConfirm }) => {
  const totalAttempts = 3;
  const attemptsLeft = 2;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col space-y-4 items-center justify-center text-white py-6 px-24 text-center">
        <h3 className="font-bold text-xl">
          {t('portfolio.get-help.consultant.title')}
        </h3>
        <p className="font-thin">
          {t('portfolio.get-help.consultant.description', {
            cost: '20.000 €',
            attemptNumber: 'second',
            totalAttempts: 'three',
          })}
        </p>
        <ConsultancyAttempts
          attemptsLeft={attemptsLeft}
          totalAttempts={totalAttempts}
        />
        <p className="pt-12 pb-6">
          {t('portfolio.get-help.consultant.consent')}
        </p>
        <Button variant="solid" onClick={onConfirm}>
          {t('portfolio.get-help.consultant.button')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConsultantModal;
