import clsx from 'clsx';

type Props = {
  currentStep: number;
  stepsCount: number;
  onStepClick: (step: number) => void;
};

const SlideshowProgress = ({
  currentStep,
  stepsCount,
  onStepClick = (step: number) => {},
}: Props) => {
  return (
    <div className="my-6 w-full">
      <div className="flex space-x-1 w-full">
        {new Array(stepsCount).fill(null).map((_, i) => (
          <div
            key={i}
            className={clsx(
              'h-2 flex-grow rounded-lg',
              i <= currentStep
                ? 'bg-accountingGreen-light'
                : 'bg-accountingBlue-light',
              i !== currentStep &&
                'cursor-pointer hover:bg-accountingGreen-light/50'
            )}
            onClick={() => onStepClick(i)}
          />
        ))}
      </div>
    </div>
  );
};

export default SlideshowProgress;
