import clsx from 'clsx';
import Clip from '../../assets/icons/clip';
import Earnings from '../../assets/icons/earnings';
import { renderGoalIcon } from '../../utils/render-goal-icon';

type Props = {
  message: {
    sender: string;
    subject: string;
    attachment?: string;
    goal?: string;
  };
  onClick: () => void;
  active?: boolean;
};

const MessagePreview = ({ message, active, onClick }: Props) => (
  <div
    className={clsx(
      'w-full p-4 bg-accountingBlue-light rounded-lg text-white flex',
      active
        ? 'bg-accountingGreen-base bg-opacity-50'
        : 'hover:bg-accountingGreen-base hover:bg-opacity-50 cursor-pointer'
    )}
    onClick={onClick}
  >
    <div className="w-5/6 h-full flex flex-col justify-between">
      <span className="font-bold">{message.sender}</span>
      <span className="mt-2">{message.subject}</span>
    </div>
    <div className="w-1/6 h-full flex flex-col justify-between items-end">
      {message.goal && (
        <span className="h-5 w-5 rounded-full bg-white/70 p-1">
          {renderGoalIcon(message.goal)}
        </span>
      )}
      {message.attachment && (
        <span className="h-5 w-5 rounded-full fill-white">
          <Clip />
        </span>
      )}
    </div>
  </div>
);

export default MessagePreview;
