import { SVGProps } from 'react';

const Phone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M6.6 23.7c-2.9 0-5-3.3-6-5.2-.5-.9-.3-2 .4-2.8l2.8-2.8c.9-.9 2.4-.9 3.3-.1 1 1 1.6 1.2 1.9 1.2 1-.2 5-4.3 5.4-5.5-.1-.2-.3-.8-1.2-1.7-.9-.9-.9-2.4.1-3.3L15.7 1c.7-.7 1.8-.9 2.8-.4 1.9 1 5.2 3.1 5.2 6 0 3.7-13.4 17.1-17.1 17.1zm-1.1-9.2s-.1 0 0 0l-2.9 2.9v.1c1 1.9 2.6 4 4 4 2.5 0 14.9-12.4 14.9-14.9 0-1.5-2.1-3-4-4h-.1l-2.5 2.5v.2C16.5 7 16.7 8 16.7 8.6c0 1.9-5.8 7.7-7.7 7.7-1 0-2.1-.6-3.5-1.8z" />
  </svg>
);

export default Phone;
