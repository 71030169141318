import { useIntl } from 'react-intl';
import { renderGoalIcon } from '../utils/render-goal-icon';

const GoalBadge = ({ goal }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="flex items-center px-4 py-2 border border-white">
      <span className="w-8 h-8 bg-white/70 p-2 rounded-full mr-4">
        {renderGoalIcon(goal)}
      </span>
      <span className="uppercase">
        <span className="font-bold">{formatMessage({ id: goal })}</span>{' '}
        <span className="font-thin">{formatMessage({ id: 'mode' })}</span>
      </span>
    </div>
  );
};

export default GoalBadge;
