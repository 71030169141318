import Earnings from '../assets/icons/earnings';

export const renderGoalIcon = (goal: string) => {
  switch (goal) {
    case 'economic':
      return <Earnings />;
    case 'strategy': // TODO: Add strategy icon
    case 'sustainability': // TODO: Add sustainability icon
    default:
      return null;
  }
};
