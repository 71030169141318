import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { useTheme } from '../stores/theme';
// import buildOptimizedAssetPath from '../utils/build-optimized-asset-path';
import { LearningLabLogo } from '../assets/llab-logo';
import { SDABocconiLogo } from '../assets/sdabocconi-logo';
import ScrollableContainer from '../components/scrollable-container';
import { HomeHeader } from './home-header';
import { MainLayoutWithTheme } from './main-with-theme';

export const InGameLayout: FC<{
  children: ReactNode;
  side: ReactNode;
}> = props => {
  const images = useTheme(({ images }) => images);

  return (
    <MainLayoutWithTheme>
      <HomeHeader variant="game">
        <div className="flex items-center justify-start w-1/3 h-full">
          <SDABocconiLogo />
        </div>
        <div className="flex items-center justify-center w-1/3 h-full">
          {/* <img
            src={images(
              buildOptimizedAssetPath('accounting_logo_white', ['avif', 'png'])
            )}
            alt="Accounting logo"
            className="relative h-1/2 -top-1"
            style={{
              imageRendering: '-webkit-optimize-contrast',
              transform: 'scale(1.04)',
            }}
          /> */}
        </div>
        <div className="flex items-center justify-end w-1/3 h-full">
          <LearningLabLogo variant="game" />
        </div>
      </HomeHeader>

      <div className="relative flex flex-row h-[calc(100vh_-_40px)] lg:h-[calc(100vh_-_60px)] xl:h-[calc(100vh_-_100px)] w-full select-none _nodrag bg-accountingBlue-base overflow-auto">
        {props.side && (
          <div className="w-[10%] h-full py-8 px-4">{props.side}</div>
        )}

        <div
          className={clsx(
            'flex flex-col h-full',
            props.side ? 'w-[90%]' : 'w-full'
          )}
        >
          <div className="min-h-full">
            <ScrollableContainer>{props.children}</ScrollableContainer>
          </div>
        </div>
      </div>
    </MainLayoutWithTheme>
  );
};
