import { useIntl } from 'react-intl';
import { InGameLayout } from '../layout/in-game-layout';
import { Menu } from '../components/menu';
import MainContent from '../layout/main-content';
import Inbox from '../components/inbox';

const InboxPage = () => {
  const { formatMessage } = useIntl();
  return (
    <InGameLayout side={<Menu />}>
      <MainContent title={formatMessage({ id: 'inbox.title' })}>
        <Inbox />
      </MainContent>
    </InGameLayout>
  );
};

export default InboxPage;
