import { useTheme } from "../stores/theme";
import buildOptimizedAssetPath from "../utils/build-optimized-asset-path";

export const SDABocconiLogo = () => {
  const images = useTheme(({ images }) => images);

  return (
    <img
      src={images(buildOptimizedAssetPath("logo_sda", ["avif", "png"]))}
      alt="Logo SDA"
      className="h-1/3"
      style={{
        imageRendering: "-webkit-optimize-contrast",
      }}
    />
  );
};
